import React from 'react';

import {Field} from 'formik';
import {InputStyled} from './styles';
import FormLabel from '../FormLabel/FormLabel';
import {standardDefaultPropsForFields, standardPropTypesForFields} from '../constants';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {hasError} from '../formUtils';

export const PasswordInput = ({label, name, validate}) => (
	<>
		<FormLabel text={label} required={!!validate && validate.required} />
		<Field
			name={name}
			validate={validate}
			render={(formikProps) => (
				<InputStyled {...formikProps.field} hasError={hasError(formikProps)} type="password" />
			)}
		/>
		<ErrorMessage name={name} />
	</>
);

PasswordInput.propTypes = {
	...standardPropTypesForFields,
};
PasswordInput.defaultProps = {
	...standardDefaultPropsForFields,
};
