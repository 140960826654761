import styled from 'styled-components';

import {withUnit, media} from '../../../styles/utils';
import {sizes, colors, alertColors, fonts} from '../../../styles/variables';
import {HeadlineStyledH1, HeadlineStyledH3} from '../../Elements/Headline/styles';
import Text from '../../Elements/Text/Text';

import Button from '../../Elements/Button/Button';
import Section from '../../Layout/Section/Section';
import Infobox from '../../Elements/Infobox/Infobox';

export const RegisterStyled = styled.div`
	box-sizing: border-box;
	position: relative;
	margin: 0 auto;
	z-index: 1;

	${media.sm`
		width: 80%;
	`};

	${media.lg`
		width: 60%;
	`};
`;

export const SelectStyled = styled.div`
	width: 100%;
	margin: 0;
	padding: 0.5em 0 0 0;

	.select {
		width: 100%;
		margin-bottom: 0;
	}

	.form-element {
		margin-bottom: 0 !important;
	}

	span.label {
		font-size: 0.6em;
		margin: 0 1em;
		text-transform: uppercase;
		font-weight: 800;
	}

	.select-css {
		border-width: 2px;
		border-style: solid;
		border-color: ${props => (props.hasError ? alertColors.error.full : `${colors.snow}`)};
		outline: none;
		padding: 0.3em 1.4em 0.3em 0.8em;
		display: block;
		font-family: ${fonts.sans_serif};
		font-size: ${withUnit(sizes.fonts.base)};
		font-weight: 300;
		width: 100%;
		height: 2em;
		max-width: 100%;
		box-sizing: border-box;
		margin: 0;
		border-radius: 2.5em;
		appearance: none;
		background-color: ${colors.snow};
		background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat, repeat;
		background-position: right 0.7em top 50%, 0 0;
		background-size: 0.65em auto, 100%;
	}
	.select-css::-ms-expand {
		display: none;
	}
	.select-css:hover {
	}
	.select-css:focus {
		border: 2px solid ${alertColors.focus.full};
		outline: none;
	}
	.select-css option {
		font-weight: normal;
	}
`;

export const DatepickerStyled = styled.div`
	width: 100%;
	margin: 0;

	span.label {
		display: block;
		font-size: 0.6em;
		padding: 0 ${withUnit(sizes.distance.base)} ${withUnit(sizes.distance.base / 4)}
			${withUnit(sizes.distance.base)};
		text-transform: uppercase;
		font-weight: 800;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}
	.react-datepicker__input-container {
		width: 100%;
	}

	input {
		box-sizing: border-box;
		width: 100%;
		height: ${sizes.forms.inputHeight};
		border-radius: 2.5em;
		background-color: ${colors.snow};
		border-width: 2px;
		border-style: solid;
		border-color: ${props => (props.hasError ? alertColors.error.full : `${colors.snow}`)};
		outline: none;
		padding: 0 ${withUnit(sizes.distance.base)};

		&:focus {
			border: 2px solid ${alertColors.focus.full};
		}
	}
`;

export const Spacer = styled.div`
	padding: ${withUnit(sizes.distance.base)};
`;

export const HeadlineStyled = styled(HeadlineStyledH1)`
	text-align: center;
`;

export const InfoboxStyled = styled(Infobox)`
	margin-bottom: 0;
`;

export const SectionStyled = styled(Section)`
	margin-bottom: ${withUnit(sizes.distance.base * 2)};
`;

export const SubHeadlineStyled = styled(HeadlineStyledH3)`
	margin-bottom: ${withUnit(sizes.distance.base)}!important;
`;

export const LabelStyled = styled.label`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1em;

	.ap-text {
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	span {
		font-size: 1.5em;
	}
`;
export const LinkStyled = styled.p`
	color: ${colors.black};
	text-decoration: underline;
	padding: 1em 1em 0;
`;
export const ButtonStyled = styled(Button)`
	margin: 0;
	padding: 1.2em 2.5em;
	font-weight: 800;
`;

export const RememberCheckboxContainerStyled = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: -1em;
`;
export const RegisterContainerStyled = styled.div`
	padding-top: 1em;
	font-size: 0.75em;
`;

export const RegisterFormStyled = styled.div``;

export const FooterTextStyled = styled(Text)`
	font-size: 0.8em;
`;
