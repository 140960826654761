import React from 'react';
import * as Yup from 'yup';

import Column from '../../../Layout/Grid/Column';
import Dropdown from '../../../Elements/Form/Dropdown/Dropdown';
import {medium} from '../domainModels';
import {Input} from '../../../Elements/Form/InputAndTextarea/InputAndTextarea';

import Text from '../../../Elements/Text/Text';
import {SubHeadlineStyled} from '../styles';
import Checkbox from '../../../Elements/Form/Checkbox/Checkbox';
import FormRow from '../../../Elements/Form/FormRow/FormRow';
import Accordion from '../../../Elements/Accordion/Accordion';
import {forms} from '../../../../assets/language/de';
import {withAsterisk} from '../../../../utils/formHelpers';

const {label, validationMessages} = forms.Register;

export const yupSchemaShapeForContactInformationData = {
	MediaId: Yup.number().required(validationMessages.recommendation),
	MediaOthers: Yup.string(),
	Agreement: Yup.boolean().oneOf([true], validationMessages.agreement),
	captcha: Yup.string().required(),
};

export const initialValuesForContactInformationData = {
	MediaId: '14',
	MediaOthers: '',
	Agreement: false,
	Newsletter: false,
	captcha: false,
};

const ContactInformationData = () => (
	<>
		<SubHeadlineStyled>{label.recommendation}</SubHeadlineStyled>
		<FormRow>
			<Column lg={6}>
				<Dropdown label={label.media} items={medium} name="MediaId" />
			</Column>

			<Column lg={6}>
				<Input label={label.moreInfo} name="MediaOthers" />
			</Column>
		</FormRow>
		<Accordion
			data={[
				{
					title: forms.Register.label.importantHint,
					content: [<Text content={forms.Register.text.hint1} key={Math.random()} />],
				},
			]}
		/>
		<FormRow>
			<Column>
				<Checkbox name="Agreement" label={withAsterisk(label.agreement)} />
			</Column>
			<Column>
				<Checkbox name="Newsletter" label={label.newsletter} />
			</Column>
		</FormRow>
	</>
);

export default ContactInformationData;
