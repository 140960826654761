import React, {useEffect} from 'react';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';
import {RegisterStyled, HeadlineStyled, RegisterFormStyled} from './styles';
import Text from '../../Elements/Text/Text';
import Button from '../../Elements/Button/Button';
import Column from '../../Layout/Grid/Column';

import AccessData, {initialValuesForAccessData, yupSchemaShapeForAccessData} from './SubForms/AccessData';

import ContactInformationData, {
	initialValuesForContactInformationData,
	yupSchemaShapeForContactInformationData,
} from './SubForms/ContactInformationData';
import PersonalData, {initialValuesForPersonalData, yupSchemaShapeForPersonalData} from './SubForms/PersonalData';
import SubmitForm from '../SubmitForm/SubmitForm';

import {userRegister} from '../../../state/actions/userManagement';
import Section from '../../Layout/Section/Section';
import FormRow from '../../Elements/Form/FormRow/FormRow';
import Captcha from '../../Elements/Form/Captcha/Captcha';
import {captchaValidation} from '../../../utils/validations';

import {forms} from '../../../assets/language/de';

const {text, label, validationMessages} = forms.Register;

// initial / schema data

const registerSchema = Yup.object().shape({
	...yupSchemaShapeForAccessData,
	...yupSchemaShapeForContactInformationData,
	...yupSchemaShapeForPersonalData,
	captcha: captchaValidation(validationMessages.captcha),

	// todo: not needed anymore?!
	// Agreement: Yup.boolean().required()
	// Newsletter: Yup.boolean(),
	// Magazine: Yup.boolean()
});

// only fields defined in initialValues are automatically touched before validation,
// that's why we initialize here even with empty or falsy values
const initialValues = {
	...initialValuesForAccessData,
	...initialValuesForContactInformationData,
	...initialValuesForPersonalData,

	captcha: '',
};

/** @component */
const Register = (props) => {
	const {user, handleRegister} = props;
	useEffect(() => {
		if (user) {
			navigate('/');
		}
	});
	return (
		<RegisterStyled className="ap-register">
			<HeadlineStyled>Registrierung</HeadlineStyled>
			<SubmitForm
				initialValues={initialValues}
				onSubmit={handleRegister}
				validationSchema={registerSchema}
				renderInnerForm={(formikProps) => (
					<>
						<RegisterFormStyled>
							<Text center content={text.intro} />
							<Text center content={text.mandatory} />
							<Section>
								<AccessData />
								<PersonalData />
								<ContactInformationData />
							</Section>
							<Section center>
								<FormRow>
									<Column>
										<Captcha name="captcha" />
									</Column>
								</FormRow>
								<FormRow>
									<Column>
										<Button disabled={formikProps.isSubmitting} text={label.send} type="submit" />
									</Column>
								</FormRow>
							</Section>
						</RegisterFormStyled>
					</>
				)}
			/>
		</RegisterStyled>
	);
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

const mapDispatchToProps = (dispatch) => ({
	handleRegister: (data) => {
		const payload = {...data};
		dispatch(userRegister(payload));
	},
});

Register.propTypes = {
	user: PropTypes.shape({
		UserId: PropTypes.string,
	}),
	handleRegister: PropTypes.func,
};

Register.defaultProps = {
	user: null,
	handleRegister: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
