import React from 'react';
import * as Yup from 'yup';

import Row from '../../../Layout/Grid/Row';
import Column from '../../../Layout/Grid/Column';
import {Input} from '../../../Elements/Form/InputAndTextarea/InputAndTextarea';
import {SubHeadlineStyled, SectionStyled, InfoboxStyled} from '../styles';
import {PasswordInput} from '../../../Elements/Form/InputAndTextarea/PasswordInput';
import FormRow from '../../../Elements/Form/FormRow/FormRow';
import {forms, mailRegExp} from '../../../../assets/language/de';
import {withAsterisk} from '../../../../utils/formHelpers';

const {text, label, validationMessages} = forms.Register;

export const yupSchemaShapeForAccessData = {
	UserName: Yup.string()
		.min(5, validationMessages.userName.ifTooShort)
		.max(50, validationMessages.userName.ifTooLong)
		.required(validationMessages.userName.ifEmpty),
	Password: Yup.string()
		.min(8, validationMessages.password.ifTooShort)
		.max(50, validationMessages.password.ifTooLong)
		.required(validationMessages.password.ifEmpty)
		.test('regex', validationMessages.password.ifFalseFormat, (val) => mailRegExp.test(val)),
	PasswordConfirm: Yup.string()
		.oneOf([Yup.ref('Password'), null], validationMessages.passwordConfirm.ifNoMatch)
		.required(validationMessages.passwordConfirm.ifEmpty),
};

export const initialValuesForAccessData = {
	UserName: '',
	Password: '',
	PasswordConfirm: '',
};

const AccessData = () => (
	<SectionStyled>
		<Row styles={{alignItems: 'flex-end'}}>
			<Column md={6}>
				<>
					<SubHeadlineStyled>Zugangsdaten</SubHeadlineStyled>
					<FormRow>
						<Column>
							<Input label={withAsterisk(label.userName)} name="UserName" />
						</Column>
					</FormRow>

					<FormRow>
						<Column>
							<PasswordInput label={withAsterisk(label.password)} name="Password" />
						</Column>
					</FormRow>

					<FormRow>
						<Column>
							<PasswordInput label={withAsterisk(label.passwordConfirm)} name="PasswordConfirm" />
						</Column>
					</FormRow>
				</>
			</Column>
			<Column md={6}>
				<FormRow>
					<Column>
						<InfoboxStyled
							className="ap-register-info"
							theme="colored"
							icon="community"
							text={text.infobox}
						/>
					</Column>
				</FormRow>
			</Column>
		</Row>
	</SectionStyled>
);

export default AccessData;
