import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout/Layout';

import Section from '../components/Layout/Section/Section';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';

import Register from '../components/Container/Register/Register';
import {PageTemplateStyled} from '../templates/styles';

const RegisterPage = ({location}) => (
	<>
		<Meta title={appendSeoTitleSuffix('Registrier dich bei MS-Life')} />
		<Layout location={location}>
			<PageTemplateStyled>
				<Section showPadding>
					<Register />
				</Section>
			</PageTemplateStyled>
		</Layout>
	</>
);

RegisterPage.propTypes = {
	location: PropTypes.shape({pathname: PropTypes.string}).isRequired,
};

export default RegisterPage;
