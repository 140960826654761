import React from 'react';
import * as Yup from 'yup';

import Column from '../../../Layout/Grid/Column';
import {Input} from '../../../Elements/Form/InputAndTextarea/InputAndTextarea';

import {SubHeadlineStyled, SectionStyled} from '../styles';
import FormRow from '../../../Elements/Form/FormRow/FormRow';
import {forms} from '../../../../assets/language/de';
import {withAsterisk} from '../../../../utils/formHelpers';
import {emailValidation} from '../../../../utils/validations';
import Dropdown from '../../../Elements/Form/Dropdown/Dropdown';
import {salutations} from '../domainModels';

const {label, validationMessages} = forms.Register;

export const yupSchemaShapeForPersonalData = {
	FirstName: Yup.string()
		.min(2, validationMessages.forename.ifTooShort)
		.required(validationMessages.forename.ifEmpty),
	LastName: Yup.string().min(2, validationMessages.forename.ifTooShort).required(validationMessages.forename.ifEmpty),
	Email: emailValidation(validationMessages.email.ifEmpty, validationMessages.email.ifNotValid),
	Salutation: Yup.string().required(validationMessages.recommendation),
};

export const initialValuesForPersonalData = {
	FirstName: '',
	LastName: '',
	Email: '',
};

const PersonalData = () => (
	<SectionStyled>
		<SubHeadlineStyled>Persönliche Daten</SubHeadlineStyled>
		<FormRow>
			<Column lg={6}>
				<Dropdown label={withAsterisk(label.salutation)} items={salutations} name="Salutation" />
			</Column>
			<Column lg={6}>
				<Input label={withAsterisk(label.email)} name="Email" />
			</Column>
		</FormRow>
		<FormRow>
			<Column lg={6}>
				<Input label={withAsterisk(label.forename)} name="FirstName" />
			</Column>

			<Column lg={6}>
				<Input label={withAsterisk(label.surname)} name="LastName" />
			</Column>
		</FormRow>
	</SectionStyled>
);

export default PersonalData;
