/* eslint-disable camelcase */

import config from '../../../config';

// export data
export const countries = config.formselectdata.country.map(({uid, country_name}) => ({
	type: uid,
	label: country_name,
}));

export const federalStates = config.formselectdata.state.map(({uid, state_name}) => ({
	type: uid,
	label: state_name,
}));

export const titles = config.formselectdata.title.map(({uid, title_name}) => ({
	type: uid,
	label: title_name,
}));

export const medium = config.formselectdata.medium.map(({uid, medium_name}) => ({
	type: uid,
	label: medium_name,
}));

const salutationsList = [
	{
		label: 'Frau',
		type: 'female',
	},
	{
		label: 'Herr',
		type: 'male',
	},
	{
		label: 'Andere',
		type: 'divers',
	},
];

export const salutations = salutationsList.map((salutation) => ({
	type: salutation.type, // because we select "Frau" or "Herr", not an id
	label: salutation.label,
}));
